import st from "./Main.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

import swiper1 from "./images/swiper/swiper1.webp";
import swiper2 from "./images/swiper/swiper2.webp";
import swiper3 from "./images/swiper/swiper3.webp";
import swiper4 from "./images/swiper/swiper4.webp";
import swiper5 from "./images/swiper/swiper5.webp";
// import swiper6 from "./images/swiper/swiper6.png";
// import swiper7 from "./images/swiper/swiper7.png";
// import swiper8 from "./images/swiper/swiper8.png";

import arrowRight from "./images/arrowright.png";
import share from "./images/share.png";
import upload from "./images/upload.png";
import reduce from "./images/reduce.png";
import deleteIcon from "./images/delete.png";
import info from "../Top/images/info.png";
import mobile from "./images/mobile.png";
import pc from "./images/pc.png";
import star from "./images/star.svg";
import halfStar from "./images/star-half.png";
import more from "./images/more.png";
import flag from "./images/flag.png";
import country from "./images/country.png";
import down from "./images/down.png";
import starGray from "./images/star-gray.png";
import user1 from "./images/users/user1.png";
import user2 from "./images/users/user2.png";
import user3 from "./images/users/user3.png";
import game1 from "./images/games/game1.png";
import game2 from "./images/games/game2.png";
import game3 from "./images/games/game3.png";
import game4 from "./images/games/game4.png";
import game5 from "./images/games/game5.png";
import game6 from "./images/games/game6.png";

const Main = (props) => {
    const { downLoadApp } = props;

    const clickHandler = () => {
        downLoadApp && downLoadApp();
    };

    const swiperList = [swiper1, swiper2, swiper3, swiper4,swiper5];



    const rateList = [
        { num: 5, width: "90%" },
        { num: 4, width: "20%" },
        { num: 3, width: "10%" },
        { num: 2, width: "0%" },
        { num: 1, width: "5%" },
    ];

    const footerList = [
        {
            title: "Google Play",
            children: ["Passe de jogo", "Pontos de jogo", "Cartões-presente", "Resgatar", "Politica de reembolso"],
        },
        {
            title: "CrianÃ§as & Família",
            children: ["Guia dos Pais", "Compartilhamento Familiar"],
        },
    ];

    const evaluateList = [
        // {
        //     avatar: user1,
        //     userName: "Lisa G",
        //     time: "November 15, 2023",
        //     content: "Update..plan on spending bank if you want to play. Wins are not enough to sustain playing for a decent amount of time. I agree with the other reviews stating that the winnings become less and less. The more coins you purchase, the more quickly you lose them. After purchasing the piggy bank, the price skyrocketed for any additional purchase. The more dedicated you are to playing, the higher the prices go. There are plenty of other slot apps available that have a much higher win/lose ratio.",
        //     userfulDesc: "2,008 people found this review helpful",
        // },
        {
            avatar: user2,
            userName: "Karen S",
            time: "October 15, 2023",
            content:
                "Grande variedade de jogos populares que também estão nos cassinos ao vivo e muita diversão para jogar! Assistir aos anúncios sozinho pode sustentar o jogo, mas às vezes pode ser terrivelmente lento. Esteja preparado para fazer compras ou tenha muita paciência. Às vezes, você tem que assistir a um anúncio de 30 segundos a um minuto entre algumas mãos de jogo, e isso pode durar várias mãos. Em alguns jogos, você não pode cumprir os requisitos de bônus a menos que compre dinheiro para jogar. AINDA, meu favorito de todos os aplicativos de cassino para jogar!",
            userfulDesc: "Essa avaliação foi marcada como útil por 3.394 pessoas",
        },
        {
            avatar: user3,
            userName: "Donita C",
            time: "September 14, 2022",
            content: "Eu realmente gosto deste jogo. Tem uma variedade de slots diferentes para jogar. Posso ganhar moedas extras enquanto jogo. Não há muitos anúncios para assistir. A quantidade de moedas na hora de comprar é inferior a outros jogos, mas pela qualidade deste jogo valem a pena na minha opinião. Baixei e excluí outros aplicativos depois de reproduzi-los.",
            userfulDesc: "Essa avaliação foi marcada como útil por 1.110 pessoas",
        },
    ];

    const gameList1 = [
        {
            img: game1,
            title: "Lightning Link Casino Slots",
            desc: "Product Madness",
            star: "4.3",
        },
        {
            img: game2,
            title: "Quick Hit Casino Slot Games",
            desc: "SciPlay",
            star: "4.7",
        },
        {
            img: game3,
            title: "Lucky Time Slots Casino Games",
            desc: "DGN Games",
            star: "4.8",
        },
        {
            img: game4,
            title: "Cash Tornado™ Slots - Casino",
            desc: "Zeroo Gravity Games",
            star: "4.6",
        },
        {
            img: game5,
            title: "Gold Fish Casino Slot Games",
            desc: "SciPlay",
            star: "4.8",
        },
    
    ];


    return (
        <div className={st.container}>
            <div className={st.content}>
                <div className={st.contentLeft}>
                    <div className={st.swiperContainer}>
                        <Swiper
                            slidesPerView="auto"
                            className={st.swiperContent}
                            navigation={{ nextEl: ".navigation-next", prevEl: ".navigation-prev" }}
                            spaceBetween={12}
                            modules={[Navigation]}>
                            {swiperList.map((v, key) => {
                                return (
                                    <SwiperSlide key={key} style={{ width: "auto" }}>
                                        <img src={v} alt="" className={st.swiperImg} onClick={clickHandler} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div className={st.contentGameInfo}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                            Sobre este jogo
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentGameInfoText}>
                        🔥 Bem-vindo ao SPBET-O reino do jogo intenso!🔥
                            <br />
                            SPBET é um aplicativo de jogo cativante e apaixonante que permite que você experimente o emocionante mundo do entretenimento sem gastar um centavo.
                            <br />
                            <br />
                            🌟 Funcionalidades do jogo 🌟
                            <br />
                            🎮 Seleção diversificada de jogos: mergulhe em uma variedade de jogos emocionantes para satisfazer seus desejos por diferentes experiências de jogo!
<br/>
🏆 Desafios competitivos: participe de partidas competitivas acirradas e compita contra jogadores de todo o mundo!
<br/>
🔥 Baixe agora para desfrutar de uma experiência de jogo emocionante e enfrentar desafios ilimitados!🔥
<br/>
                        </div>
                    </div>

                    <div className={st.contentSafe}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        DSeguranÃ§a de dados
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentSafeText}>
                        A segurança começa com a compreensão de como os desenvolvedores coletam e compartilham seus dados. As práticas de privacidade e segurança de dados podem variar de acordo com seu uso, região e idade. O desenvolvedor forneceu essas informações e pode atualizá-las com o tempo.
                        </div>
                        <div className={st.contentSafeTable}>
                            <div className={st.contentSafeTableItem}>
                                <img src={share} alt="" />
                                <div>
                                Este aplicativo pode compartilhar esses tipos de dados com terceiros
                                    <br />
                                    Dispositivo ou outros IDs
                                </div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={upload} alt="" />
                                <div>
                                Este aplicativo pode coletar estes tipos de dados
                                    <br />
                                    Dispositivo ou outros IDs
                                </div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={reduce} alt="" />
                                <div>Os dados são criptografados em trânsito</div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={deleteIcon} alt="" />
                                <div>Você pode solicitar que os dados sejam excluídos</div>
                            </div>
                            <div className={st.contentSafeTableDetail} onClick={clickHandler}>
                            Veja detalhes
                            </div>
                        </div>
                    </div>
                    <div className={st.contentRate}>
                        <div className={st.contentRateTitle}>
                            <div className={st.infoTitle} onClick={clickHandler}>
                            Avaliações e comentários
                                <img src={arrowRight} alt="" />
                            </div>
                            <div className={st.contentRateTitleR} onClick={clickHandler}>
                            As classificações e comentários são verificados
                                <img src={info} alt="" />
                            </div>
                        </div>
                        <div className={st.contentRateDevice}>
                            <div className={st.contentRateDeviceItem} onClick={clickHandler}>
                                <img src={mobile} alt="" />
                                Phone
                            </div>
                            <div className={st.contentRateDeviceItemPc} onClick={clickHandler}>
                                <img src={pc} alt="" />
                                Tablet
                            </div>
                        </div>
                        <div className={st.contentRateDetail}>
                            <div className={st.contentRateDetailL}>
                                <div className={st.contentRateDetailLNum}>4.9</div>
                                <div className={st.contentRateDetailLStar}>
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={halfStar} alt="" />
                                </div>
                                <div>422 mil avaliações</div>
                            </div>
                            <div className={st.contentRateDetailR}>
                                {rateList.map((v, key) => {
                                    return (
                                        <div key={key} className={st.contentRateDetailRItem}>
                                            <div className={st.contentRateDetailRItemNum}>{v.num}</div>
                                            <div className={st.contentRateDetailRItemProgress}>
                                                <div className={st.contentRateDetailRItemProgressInner} style={{ width: v.width }}></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={st.contentRateEvaluate}>
                            {evaluateList.map((v, key) => {
                                return (
                                    <div key={key} className={st.contentRateEvaluateItem} onClick={clickHandler}>
                                        <div className={st.contentRateEvaluateItemTitle}>
                                            <img src={v.avatar} alt="" className={st.contentRateEvaluateItemTitleAvatar} />
                                            <div className={st.contentRateEvaluateItemTitleName}>{v.userName}</div>
                                            <img src={more} alt="" className={st.contentRateEvaluateItemTitleMore} />
                                        </div>
                                        <div className={st.contentRateEvaluateItemRate}>
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <div>{v.time}</div>
                                        </div>
                                        <div className={st.contentRateEvaluateItemContent}>{v.content}</div>
                                        <div className={st.contentRateEvaluateItemUserful}>{v.userfulDesc}</div>
                                        <div className={st.contentRateEvaluateItemIsUserful}>
                                            <div className={st.contentRateEvaluateItemIsUserfulText}>Você achou isso útil?</div>
                                            <div className={st.contentRateEvaluateItemIsUserfulBtn}>Sim</div>
                                            <div className={st.contentRateEvaluateItemIsUserfulBtn}>Não</div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className={st.contentRateEvaluateMore}>Ver todas as avaliações</div>
                        </div>
                    </div>
                    <div className={st.contentNew}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        O que há de novo
                        </div>
                        <div className={st.contentNewContent}>
                            Obtenha nossos NOVOS jogos em nossa atualização mais recente!<br />
                            Para a melhor experiência e mais DIVERSÃO, eliminamos bugs e otimizamos seu jogo.<br />
                        </div>
                    </div>
                </div>
                <div className={st.contentRight}>
                    <div className={st.infoTitle} onClick={clickHandler}>
                   Suporte de aplicativo
                        <img src={down} alt="" />
                    </div>
                    <div className={st.contentRightOther}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Jogos semelhantes
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentRightOtherGameList}>
                            {gameList1.map((v, key) => {
                                return (
                                    <div key={key} className={st.contentRightGameItem} onClick={clickHandler}>
                                        <img src={v.img} alt="" className={st.contentRightGameItemImg} />
                                        <div className={st.contentRightGameItemImfo}>
                                            <div className={st.contentRightGameItemImfoTitle}>{v.title}</div>
                                            <div className={st.contentRightGameItemImfoDesc}>{v.desc}</div>
                                            <div className={st.contentRightGameItemImfoStar}>
                                                {v.star}
                                                <img src={starGray} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </div>
            </div>
            <div className={st.report} onClick={clickHandler}>
                <img src={flag} alt="" />
                Flag as inappropriate
            </div>
            <div className={st.footer}>
                <div className={st.divider}></div>
                <div className={st.footerInfo}>
                    {footerList.map((v, key) => {
                        return (
                            <div key={key} className={st.footerInfoItem}>
                                <div className={st.footerInfoItemTitle} onClick={clickHandler}>
                                    {v.title}
                                </div>
                                {v.children.map((v1, key1) => {
                                    return (
                                        <div key={key1} className={st.footerInfoItemText} onClick={clickHandler}>
                                            {v1}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={st.desc} onClick={clickHandler}>
                <div>Termos de serviço</div>
                <div>Privacidade</div>
                <div>Sobre o Google Play</div>
                <div>Desenvolvedores</div>
                <div>Lojas do Google</div>
                <img src={country} alt="" />
                <div>Brazil</div>
            </div>
            <div style={{ width: "100%", height: "36px" }}></div>
        </div>
    );
};

export default Main;
